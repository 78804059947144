import { useSinglePrismicDocument } from '@prismicio/react';
import { Layout, FAQs } from '../../components';

const HowItWorks = () => {
  const [document] = useSinglePrismicDocument('faqs');

  const faqBlock = (document?.data.faq_block as any[]) || [];

  const faqs = faqBlock.map((faq: any) => ({
    question: faq.title[0]?.text,
    answer: faq.content[0]?.text,
  }));

  return <Layout main={<FAQs faqs={faqs} />} />;
};

export default HowItWorks;
