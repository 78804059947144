/* eslint-disable no-restricted-globals */
import { useEffect } from 'react';
import { logEvent } from 'firebase/analytics';
import { useAnalytics } from 'reactfire';

const Analytics = () => {
  const analytics = useAnalytics();

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_location: location.href,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.href]);

  return null;
};

export default Analytics;
