import { ReactText, Fragment } from 'react';
import { Tab } from '@headlessui/react';
import classNames from 'classnames';

type HomeTabProps = {
  children: ReactText;
};

const HomeTab = ({ children }: HomeTabProps) => (
  <Tab as={Fragment}>
    {({ selected }) => (
      <button
        className={classNames('border-b-2 p-2 text-default_lg font-medium', {
          'border-b-transparent text-gray-500 dark:text-gray-400': !selected,
          'border-b-primary-400 text-black dark:text-white': selected,
        })}
        type="button"
      >
        {children}
      </button>
    )}
  </Tab>
);

export default HomeTab;
