import { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import type { GiftCard } from '../../types';
import { useCheckout } from '../../hooks';
import Layout from '../Layout';
import OrderSummaryCard from '../OrderSummaryCard';
import Input from '../Input';
// import RadioGroup from '../RadioGroup';
import CheckoutNavigationButtons from '../CheckoutNavigationButtons';


type CheckoutStepThreeProps = {
  giftCard: GiftCard;
};

const CheckoutStepThree = ({ giftCard }: CheckoutStepThreeProps) => {
  const [autofilled, setAutoFilled] = useState<boolean>(false);
  
  const functions = useFunctions();
  const checkForExistingStripeCustomer = httpsCallable(
    functions,
    'checkForExistingStripeCustomer'
  );
  const createNewStripeCustomer = httpsCallable(
    functions,
    'createNewStripeCustomer'
  );
  const {
    values,
    errors,
    touched,
    recipientIsMyself,
    handleChange,
    handleBlur,
    setFieldValue,
    setCustomer,
  } = useCheckout();
  
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const zorroUserId = params.get('zorro_user_id');

  const getShouldDisableContinue = () => {
    if (!values.customerFirstName) return true;

    if (!values.customerLastName) return true;

    if (values.deliveryMethod === 'email' && !values.customerEmailAddress)
      return true;

    if (!recipientIsMyself) {
      if (!values.recipientFirstName) return true;

      if (!values.recipientLastName) return true;

      if (values.deliveryMethod === 'email' && !values.recipientEmailAddress)
        return true;
    }

    if (values.deliveryMethod === 'sms' && !values.mobileNumber) return true;

    if (
      errors.customerFirstName ||
      errors.customerLastName ||
      errors.customerEmailAddress ||
      errors.recipientFirstName ||
      errors.recipientLastName ||
      errors.recipientEmailAddress ||
      errors.mobileNumber
    )
      return true;
    

    return false;
  };  

  useEffect(() => {
    checkForExistingStripeCustomer(zorroUserId).then((res: any) => {
      if (res?.data && res.data.length) {
        const { email, name } = res.data[0];

        if (!email || !name || values.customerEmailAddress) return;

        const names = name.split(' ');        
        
        setFieldValue('customerFirstName', names[0])

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        names.length > 1 ? setFieldValue('customerLastName', names[1]) : setFieldValue('customerFirstName', names[0]);
        
        setFieldValue('customerEmailAddress', email);     
        
        setAutoFilled(true);
      }  
      // eslint-disable-next-line
    }).catch(err => console.log(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Layout
        main={
          <>
            <OrderSummaryCard giftCard={giftCard} />
            <Input
              id="customerFirstName"
              name="customerFirstName"
              label="Your first name"
              value={values.customerFirstName}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              touched={touched}
            />
            <Input
              id="customerLastName"
              name="customerLastName"
              label="Your last name"
              value={values.customerLastName}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              touched={touched}
            />
            {/* <RadioGroup
              value={values.deliveryMethod}
              onChange={(value) => setFieldValue('deliveryMethod', value)}
              label="Choose a delivery method"
              options={[
                {
                  value: 'email',
                  label: 'Email',
                },
                {
                  value: 'sms',
                  label: 'Text message (SMS)',
                },
              ]}
            /> */}
            {values.deliveryMethod === 'email' && (
              <Input
                id="customerEmailAddress"
                type="email"
                name="customerEmailAddress"
                label="Your email address"
                value={values.customerEmailAddress}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors}
                touched={touched}
              />
            )}
            {values.deliveryMethod === 'sms' && (
              <Input
                id="mobileNumber"
                type="tel"
                name="mobileNumber"
                label={
                  recipientIsMyself
                    ? 'Your mobile number'
                    : 'Recipients mobile number'
                }
                value={values.mobileNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors}
                touched={touched}
              />
            )}
            {!recipientIsMyself && (
              <>
                <Input
                  id="recipientFirstName"
                  name="recipientFirstName"
                  label="Recipients first name"
                  value={values.recipientFirstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                />
                <Input
                  id="recipientLastName"
                  name="recipientLastName"
                  label="Recipients last name"
                  value={values.recipientLastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                />
                {values.deliveryMethod === 'email' && (
                  <Input
                    id="recipientEmailAddress"
                    type="email"
                    name="recipientEmailAddress"
                    label="Recipients email address"
                    value={values.recipientEmailAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors}
                    touched={touched}
                  />
                )}
              </>
            )}
          </>
        }
      />
      <CheckoutNavigationButtons
        onContinue={(incrementCurrentStepIndex) => {
          checkForExistingStripeCustomer(zorroUserId).then(
            (checkForExistingStripeCustomerResponse: any) => {
              const { data } = checkForExistingStripeCustomerResponse;
              const exists = Boolean(data.length);
              const customer = data[0];

              if (!exists) {
                return createNewStripeCustomer({
                  email: values.customerEmailAddress,
                  zorroUserId,
                  name: `${values.customerFirstName} ${values.customerLastName}`,
                }).then((createNewStripeCustomerResponse: any) => {
                  setCustomer(createNewStripeCustomerResponse.data);

                  incrementCurrentStepIndex();
                });
              }

              setCustomer(customer);

              return incrementCurrentStepIndex();
            }
          );
        }}
        disableContinue={autofilled && recipientIsMyself ? false : getShouldDisableContinue()}
      />
    </>
  );
};

export default CheckoutStepThree;
