import { Link } from 'react-router-dom';
import { IoArrowBackOutline } from 'react-icons/io5';

type BackButtonProps = {
  href: string;
};

const BackButton = ({ href }: BackButtonProps) => (
  <Link
    className="flex h-10 w-10 items-center justify-center rounded-full bg-dark-foreground text-white"
    to={href}
  >
    <IoArrowBackOutline />
  </Link>
);

export default BackButton;
