import { useState, useEffect } from 'react';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import type { GiftCard } from '../../types';
import { useCheckout, useDarkMode } from '../../hooks';
import Layout from '../Layout';
import OrderSummaryCard from '../OrderSummaryCard';
import CheckoutForm from '../CheckoutForm';
import Button from '../Button';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
);

type CheckoutStepFiveProps = {
  giftCard: GiftCard;
};

const CheckoutStepSix = ({ giftCard }: CheckoutStepFiveProps) => {
  const [clientSecret, setClientSecret] = useState('');
  const functions = useFunctions();
  const createPaymentIntent: any = httpsCallable(
    functions,
    'createPaymentIntent'
  );
  const { values, customer } = useCheckout();
  const { amount } = values;
  const darkMode = useDarkMode();
  const options = {
    appearance: {
      rules: {
        '.Block': {
          backgroundColor: darkMode ? '#000' : '#fff',
          borderColor: darkMode ? '#262626' : '#dce0e2',
        },
        '.BlockDivider': {
          backgroundColor: darkMode ? '#000' : '#fff',
        },
        '.Input': {
          backgroundColor: darkMode ? '#0D0D0D' : '#f4f4f5',
          borderColor: darkMode ? '#262626' : '#dce0e2',
          borderRadius: '9999px',
          boxShadow: 'none',
          color: darkMode ? '#fff' : '#0d2c3a',
          fontSize: '1rem',
          lineHeight: '1.5rem',
          padding: '0.6875rem',
        },
        '.Input:focus': {
          borderColor: 'rgb(94, 234, 212)',
          boxShadow: 'none',
        },
        '.Input::placeholder': {
          color: '#65757E'
        },
        '.Label': {
          color: darkMode ? '#fff' : '#000',
          fontSize: '13px',
          fontWeight: '700',
          lineHeight: '1.25rem',
        },
        '.RedirectText': {
          color: darkMode ? '#fff' : '#0d2c3a',
        },
        '.Tab': {
          backgroundColor: darkMode ? '#0D0D0D' : '#F3F4F5',
          borderColor: darkMode ? '#262626' : '#E8EAEB',
          color: darkMode ? '#fff' : '#000',
          fontWeight: '500',
        },
        '.Tab:hover': {
          color: darkMode ? '#fff' : '#000',
        },
        '.Tab--selected': {
          borderColor: darkMode ? '#fff' : '#000',
          boxShadow: 'none',
          color: darkMode ? '#fff' : '#000',
        },
        '.Tab:focus': {
          borderColor: darkMode ? '#fff' : '#000',
          boxShadow: 'none',
          color: darkMode ? '#fff' : '#000',
        },
        '.Tab--selected:hover': {
          color: darkMode ? '#fff' : '#000',
        },
        '.Tab--selected:focus': {
          borderColor: darkMode ? '#fff' : '#000',
          boxShadow: 'none',
        },
        '.Icon': {
          fill: '#7DB9FF!important'
        }
      },
      variables: {
        colorIconTab: darkMode ? '#fff' : 'inherit',
        colorIconTabHover: darkMode ? '#fff' : 'inherit',
        colorPrimary: '#7DB9FF',
        colorLinkBrand200: '#7DB9FF',
        iconColor: '#7DB9FF',
        fontFamily: 'Bricolage Grotesque',
        tabIconSelectedColor: darkMode ? '#fff' : '#000',
      }
    },
    clientSecret,
    fonts: [
      {
        family: 'Bricolage Grotesque',
        src: `url(https://fonts.gstatic.com/s/bricolagegrotesque/v2/3y9K6as8bTXq_nANBjzKo3IeZx8z6up5BeSl9D4dj_x9PpZBMlGIInHWVyNJ.woff2)`,
        weight: '400',
      },
      {
        family: 'Bricolage Grotesque',
        src: `url(https://fonts.gstatic.com/s/bricolagegrotesque/v2/3y9K6as8bTXq_nANBjzKo3IeZx8z6up5BeSl9D4dj_x9PpZBMlGIInHWVyNJ.woff2)`,
        weight: '500',
      },
    ],
  };

  const createPaymentIntentOptions: any = {
    amount,
    values,
    giftCard,
  };

  if (customer) {
    createPaymentIntentOptions.customer = customer.id;
  }

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const zorroUserId = params.get('zorro_user_id');

  if (zorroUserId) {
    createPaymentIntentOptions.zorroUserId = zorroUserId;
  }

  useEffect(() => {
    createPaymentIntent(createPaymentIntentOptions).then(({ data }: any) =>
      setClientSecret(data.client_secret)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  return (
    <Layout
      main={
        <>
          <OrderSummaryCard giftCard={giftCard} />
          {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
          )}
          <Button
            component="a"
            color="secondary"
            href={`/?zorro_user_id=${zorroUserId}`}
            fullWidth
          >
            Cancel transaction
          </Button>
        </>
      }
    />
  );
};

export default CheckoutStepSix;
