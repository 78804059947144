import type { GiftCard } from '../../types';
import { useCheckout, useModal } from '../../hooks';
import Layout from '../Layout';
import AmountInput from '../AmountInput';
import Button from '../Button';
import AvailabilityCard from '../AvailabilityCard';
import TermsAndConditionsModal from '../TermsAndConditionsModal';
import BackButton from '../BackButton';

type CheckoutStepOneProps = {
  giftCard: GiftCard;
};

const CheckoutStepOne = ({ giftCard }: CheckoutStepOneProps) => {
  const {
    formattedRewardsPercentage,
    formattedRewardsAmount,
    incrementCurrentStepIndex,
    amountInvalid,
    getAmountInvalidMessage,
  } = useCheckout();
  const { open, handleOpen, handleClose } = useModal();
  const amountInvalidMessage = getAmountInvalidMessage();
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const zorroUserId = params.get('zorro_user_id');

  const getAvailibility = () => {
    const availability = [];

    if (giftCard.meta.online) availability.push('Online');
    if (giftCard.meta.in_store) availability.push('In-store');

    return availability.join(' & ');
  };

  return (
    <Layout
      route="CheckoutStepOne"
      header={
        <>
          <div className="grid w-full grid-cols-[2.5rem,1fr,2.5rem] items-center px-4">
            <BackButton href={`/?zorro_user_id=${zorroUserId}`} />
            <div className="flex items-center justify-center">
              <div className="text-center">
                <p className="font-medium leading-tight text-white font-secondary lowercase text-default">
                  {giftCard.brand}
                </p>
                <p className="text-base font-medium leading-tight text-white text-default_sm">
                  {getAvailibility()}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full space-y-4 bg-white p-8 dark:bg-dark-background">
            <div className="text-center">
              <p className="text-center text-black dark:text-white text-default_sm">
                {`${formattedRewardsPercentage} back from this purchase`}
              </p>
              <p className="text-primary-400 text-default">{`You're getting ${formattedRewardsAmount} Airtime Rewards!`}</p>
            </div>
            <p className="text-center leading-tight text-black dark:text-white text-default">
              {`Select ${giftCard.brand} gift card amount${
                !giftCard.denominations ? ' or manually enter below' : ''
              }`}
            </p>
            <AmountInput giftCard={giftCard} />
            {!giftCard.denominations && amountInvalidMessage && (
              <p className="text-center text-sm text-red-500">
                {amountInvalidMessage}
              </p>
            )}
            <Button
              onClick={incrementCurrentStepIndex}
              disabled={!giftCard.denominations && amountInvalid}
              fullWidth
            >
              Continue
            </Button>
          </div>
        </>
      }
      headerClassName="px-0 pb-0"
      main={
        <>
          <AvailabilityCard giftCard={giftCard} />
          <Button color="secondary" onClick={handleOpen} fullWidth>
            Terms &amp; Conditions
          </Button>
          <TermsAndConditionsModal
            open={open}
            onClose={handleClose}
            copy={giftCard.meta.terms_and_conditions_copy}
          />
        </>
      }
    />
  );
};

export default CheckoutStepOne;
