import type { GiftCard } from '../../types';
import { useCheckout } from '../../hooks';
import GiftCardIconSquare from '../GiftCardIconSquare';

type SummaryCardProps = {
  giftCard: GiftCard;
};

const SummaryCard = ({ giftCard }: SummaryCardProps) => {
  const { formattedAmount, formattedRewardsAmount } = useCheckout();

  return (
    <div className="flex items-center space-x-4 rounded-lg border bg-gray-100 p-3 dark:bg-dark-background">
      <GiftCardIconSquare />
      <div>
        <h2 className="font-medium text-black dark:text-white font-secondary lowercase text-default">{`${formattedAmount} ${giftCard.brand} gift card`}</h2>
        <p className="text-black dark:text-white text-default_sm">{`+${formattedRewardsAmount} Airtime Rewards!`}</p>
      </div>
    </div>
  );
};

export default SummaryCard;
