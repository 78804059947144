import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Link } from 'react-router-dom';
import { IoChevronForwardOutline } from 'react-icons/io5';
import type { Purchase } from '../../types';
import { formatPrice } from '../../utils';

dayjs.extend(advancedFormat);

type PurchaseHistoryListProps = {
  purchaseHistory: Purchase[];
};

const PurchaseHistoryList = ({ purchaseHistory }: PurchaseHistoryListProps) => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const zorroUserId = params.get('zorro_user_id');

  return (
    <ul className="space-y-[0.625rem]">
      {purchaseHistory.map((purchase) => (
        <li className="h-[4.375rem] list-none" key={purchase.id}>
          <Link
            className="flex h-full items-center rounded-lg bg-white px-4 dark:bg-dark-foreground"
            to={`/purchase/${purchase.id}?zorro_user_id=${zorroUserId}`}
          >
            <p className="mr-4 border-r-2 border-r-gray-200 pr-4 font-medium dark:text-white dark:border-r-dark-background text-default_sm">
              {dayjs.unix(purchase.created).format('Do')}
              <br />
              {dayjs.unix(purchase.created).format('MMM')}
              <br />
              {dayjs.unix(purchase.created).format('YYYY')}
              <br />
            </p>
            <div className="flex flex-grow items-center justify-between">
              <div className="flex items-center space-x-4">
                <img
                  className="h-10 w-10 rounded-full border border-gray-200 object-cover"
                  src={purchase.thumbnail}
                  alt={purchase.brand}
                />
                <div>
                  <h2 className="font-medium text-black dark:text-white text-default font-secondary lowercase">
                    {purchase.brand}
                  </h2>
                  <p className="font-medium text-gray-500 dark:text-white text-default_sm">
                    {`${formatPrice(purchase.amount)} gift card`}
                  </p>
                </div>
              </div>
              <IoChevronForwardOutline
                className="text-gray-800 dark:text-white"
                size={20}
              />
            </div>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default PurchaseHistoryList;
