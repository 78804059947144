import ReactDatePicker from 'react-datepicker';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';

type DatePickerProps = {
  id: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
};

const DatePicker = ({ id, label, value, onChange }: DatePickerProps) => (
  <div className="relative space-y-2">
    <label className="label" htmlFor={id}>
      {label}
    </label>
    <ReactDatePicker
      id={id}
      selected={new Date(value)}
      onChange={(date: Date) =>
        onChange(date ? date.toISOString() : new Date().toISOString())
      }
      renderCustomHeader={({
        decreaseMonth,
        prevMonthButtonDisabled,
        monthDate,
        increaseMonth,
        nextMonthButtonDisabled,
      }) => {
        const buttonClassName =
          'p-[0.3125rem] text-gray-500 border rounded-full disabled:opacity-50';

        return (
          <div className="flex items-center">
            <div className="flex items-center justify-start">
              <button
                className={buttonClassName}
                type="button"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                aria-label="Button"
              >
                <IoChevronBackOutline size={20} />
              </button>
            </div>
            <div className="flex flex-grow items-center justify-center text-sm font-medium text-gray-800">
              {monthDate.toLocaleString('default', {
                month: 'long',
                year: 'numeric',
              })}
            </div>
            <div className="flex items-center justify-end">
              <button
                className={buttonClassName}
                type="button"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                aria-label="Button"
              >
                <IoChevronForwardOutline size={20} />
              </button>
            </div>
          </div>
        );
      }}
      dateFormat="dd/MM/yyyy h:mm aa"
      minDate={new Date()}
      showTimeSelect
    />
  </div>
);

export default DatePicker;
