import { RadioGroup as HeadlessUIRadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import { IoCheckmark } from 'react-icons/io5';
import type { RadioGroupOption } from '../../types';

type RadioGroupProps = {
  value: string;
  onChange: (value: string) => void;
  label: string;
  options: RadioGroupOption[];
};

const RadioGroup = ({ value, onChange, label, options }: RadioGroupProps) => (
  <HeadlessUIRadioGroup className="space-y-2" value={value} onChange={onChange}>
    <HeadlessUIRadioGroup.Label className="label">
      {label}
    </HeadlessUIRadioGroup.Label>
    {options.map((option) => (
      <HeadlessUIRadioGroup.Option key={option.value} value={option.value}>
        {({ checked }) => (
          <div
            className={classNames(
              'relative rounded-full border p-[0.6875rem] text-center font-medium text-black dark:text-white text-default',
              {
                'border-green-500': checked,
              }
            )}
          >
            {option.label}
            {!checked && (
              <div className="absolute top-1/2 right-2.5 -translate-y-1/2 h-[1.875rem] w-[1.875rem] rounded-full border bg-gray-100 dark:bg-dark-background" />
            )}
            {checked && (
              <div className="absolute top-1/2 right-2.5 -translate-y-1/2 h-[1.875rem] w-[1.875rem] rounded-full bg-green-500 flex justify-center items-center">
                <IoCheckmark
                  className=""
                  stroke="#fff"
                  size={22}
                />
              </div>
            )}
          </div>
        )}
      </HeadlessUIRadioGroup.Option>
    ))}
  </HeadlessUIRadioGroup>
);

export default RadioGroup;
