import classNames from 'classnames';
import type { GiftCard } from '../../types';
import { useDarkMode } from '../../hooks';
import darkInStoreIcon from '../../images/darkInStoreIcon.svg';
import inStoreIcon from '../../images/inStoreIcon.svg';
import darkOnlineIcon from '../../images/darkOnlineIcon.svg';
import onlineIcon from '../../images/onlineIcon.svg';

type AvailabilityCardProps = {
  giftCard: GiftCard;
};

const AvailabilityCard = ({ giftCard }: AvailabilityCardProps) => {
  const darkMode = useDarkMode();
  const availabilityClassName =
    'flex items-center justify-center flex-grow p-4 space-x-2.5';
  const labelClassName = 'text-black dark:text-white text-default';

  return (
    <div className="space-y-2">
      <p className="text-center text-black dark:text-white text-default">
        Availability
      </p>
      <div className="flex divide-x dark:divide-dark-background rounded-lg bg-white shadow dark:bg-dark-foreground">
        <div
          className={classNames(availabilityClassName, {
            'opacity-25': !giftCard.meta.in_store,
          })}
        >
          <img src={darkMode ? darkInStoreIcon : inStoreIcon} alt="In-store" />
          <p className={labelClassName}>In-store</p>
        </div>
        <div
          className={classNames(availabilityClassName, {
            'opacity-25': !giftCard.meta.online,
          })}
        >
          <img src={darkMode ? darkOnlineIcon : onlineIcon} alt="Online" />
          <p className={labelClassName}>Online</p>
        </div>
      </div>
    </div>
  );
};

export default AvailabilityCard;
