import { useCheckout } from '../../hooks';
import Button from '../Button';

type CheckoutNavigationButtonsProps = {
  onContinue?: (incrementCurrentStepIndex: () => void) => void;
  disableContinue?: boolean;
};

const CheckoutNavigationButtons = ({
  onContinue,
  disableContinue = false,
}: CheckoutNavigationButtonsProps) => {
  const { decrementCurrentStepIndex, incrementCurrentStepIndex } =
    useCheckout();

  return (
    <div className="fixed bottom-0 left-0 right-0 z-10 flex items-center space-x-4 bg-white p-4 dark:bg-dark-dark">
      <Button color="secondary" onClick={decrementCurrentStepIndex} fullWidth>
        Back
      </Button>
      <Button
        onClick={() => {
          if (onContinue) {
            return onContinue(incrementCurrentStepIndex);
          }

          return incrementCurrentStepIndex();
        }}
        disabled={disableContinue}
        fullWidth
      >
        Continue
      </Button>
    </div>
  );
};

export default CheckoutNavigationButtons;
