import type { ReactElement } from 'react';
import { Dialog } from '@headlessui/react';
import { IoCloseOutline } from 'react-icons/io5';

type TermsAndConditionsModalProps = {
  open: boolean;
  onClose: () => void;
  copy?: string;
  copyRichText?: ReactElement;
};

const TermsAndConditionsModal = ({
  open,
  onClose,
  copy,
  copyRichText,
}: TermsAndConditionsModalProps) => (
  <Dialog
    className="fixed inset-0 z-10 space-y-4 overflow-y-auto bg-white p-4 dark:bg-dark-background"
    open={open}
    onClose={onClose}
  >
    <div className="flex items-center justify-between">
      <Dialog.Title className="font-bold text-black dark:text-white text-default_lg">
        Terms &amp; conditions
      </Dialog.Title>
      <button
        className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 text-gray-800 dark:bg-dark-foreground dark:text-white"
        type="button"
        onClick={onClose}
        aria-label="Button"
      >
        <IoCloseOutline size={20} />
      </button>
    </div>
    {copy && <p className="text-black dark:text-white text-default">{copy}</p>}
    {copyRichText && (
      <div className="text-black dark:text-white text-default">
        {copyRichText}
      </div>
    )}
  </Dialog>
);

export default TermsAndConditionsModal;
