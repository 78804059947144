import type { ChangeEvent, FocusEventHandler, ReactNode } from 'react';
import type { FormikErrors, FormikTouched } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';
import classNames from 'classnames';

type TextareaProps = {
  id: string;
  name: string;
  label: string;
  value?: string;
  onChange?: (event: ChangeEvent) => void;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  errors?: FormikErrors<any>;
  touched?: FormikTouched<any>;
};

const Textarea = ({
  id,
  name,
  label,
  value = '',
  onChange = () => {},
  onBlur = () => {},
  errors = {},
  touched = {},
}: TextareaProps) => {
  const showErrors = errors[name] && touched[name];

  return (
    <div className="space-y-2">
      <label className="label" htmlFor={id}>
        {label}
      </label>
      <TextareaAutosize
        className={classNames('input resize-none', {
          'input-error-border': showErrors,
        })}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {showErrors && <p className="input-error">{errors[name] as ReactNode}</p>}
    </div>
  );
};

export default Textarea;
