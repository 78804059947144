import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import type { GiftCard } from '../../types';
import { useCheckout } from '../../hooks';
import {
  CheckoutStepOne,
  CheckoutStepTwo,
  CheckoutStepThree,
  CheckoutStepFour,
  CheckoutStepFive,
  CheckoutStepSix,
} from '../../components';

const Checkout = () => {
  const { sku } = useParams<{
    sku: string;
  }>();
  const [giftCard, setGiftCard] = useState<GiftCard | undefined>(undefined);
  const functions = useFunctions();
  const getGiftCard: any = httpsCallable(functions, 'getGiftCard');

  useEffect(() => {
    getGiftCard({
      sku,
    }).then(({ data }: any) => setGiftCard(data.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { values, currentStepIndex } = useCheckout();

  if (!giftCard) return null;

  const steps = [
    {
      component: <CheckoutStepOne giftCard={giftCard} />,
      enabled: true,
    },
    {
      component: <CheckoutStepTwo giftCard={giftCard} />,
      enabled: true,
    },
    {
      component: <CheckoutStepThree giftCard={giftCard} />,
      enabled: true,
    },
    {
      component: <CheckoutStepFour giftCard={giftCard} />,
      enabled: values.recipient === 'someone-else',
    },
    {
      component: <CheckoutStepFive />,
      enabled: true,
    },
    {
      component: <CheckoutStepSix giftCard={giftCard} />,
      enabled: true,
    },
  ];

  return (
    steps.filter((step) => step.enabled)[currentStepIndex]?.component || null
  );
};

export default Checkout;
