import { useAnalytics } from 'reactfire';
import { logEvent } from 'firebase/analytics';
import type { GiftCard } from '../../types';
import { useCheckout } from '../../hooks';
import Layout from '../Layout';
import OrderSummaryCard from '../OrderSummaryCard';
import RadioGroup from '../RadioGroup';
import CheckoutNavigationButtons from '../CheckoutNavigationButtons';

type CheckoutStepTwoProps = {
  giftCard: GiftCard;
};

const CheckoutStepTwo = ({ giftCard }: CheckoutStepTwoProps) => {
  const analytics = useAnalytics();
  const { values, setFieldValue } = useCheckout();

  return (
    <>
      <Layout
        main={
          <>
            <OrderSummaryCard giftCard={giftCard} />
            <RadioGroup
              value={values.recipient}
              onChange={(value) => setFieldValue('recipient', value)}
              label="Who is this gift card for?"
              options={[
                {
                  value: 'myself',
                  label: 'Myself',
                },
                {
                  value: 'someone-else',
                  label: 'Someone else',
                },
              ]}
            />
          </>
        }
      />
      <CheckoutNavigationButtons
        onContinue={(incrementCurrentStepIndex) => {
          logEvent(
            analytics,
            values.recipient === 'myself'
              ? 'start_purchase_self'
              : 'start_purchase_someoneelse'
          );

          incrementCurrentStepIndex();
        }}
      />
    </>
  );
};

export default CheckoutStepTwo;
