import type { ReactNode } from 'react';

type CheckboxProps = {
  id: string;
  checked: boolean;
  onChange: () => void;
  label: ReactNode;
};

const Checkbox = ({ id, checked, onChange, label }: CheckboxProps) => (
  <div className="flex items-center space-x-4">
    <input
      className="h-6 w-6 rounded-full border-gray-300 bg-gray-100 text-green-500 dark:border-dark-border dark:bg-dark-foreground"
      id={id}
      type="checkbox"
      checked={checked}
      onChange={onChange}
    />
    <label className="text-000 dark:text-white text-default" htmlFor={id}>
      {label}
    </label>
  </div>
);

export default Checkbox;
