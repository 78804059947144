import type { ReactText } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

type ButtonProps = {
  component?: 'button' | 'a';
  type?: 'button' | 'submit';
  color?: 'primary' | 'secondary';
  onClick?: () => void;
  href?: string;
  children: ReactText;
  fullWidth?: boolean;
  disabled?: boolean;
};

const Button = ({
  component = 'button',
  type = 'button',
  color = 'primary',
  onClick = () => {},
  href = '/',
  children,
  fullWidth = false,
  disabled = false,
}: ButtonProps) => {
  const className = classNames(
    'block px-[31px] p-[0.6875rem] font-medium text-center text-black border rounded-full font-secondary lowercase text-default',
    {
      'dark:border-white dark:text-white': color === 'secondary',
      'bg-primary-400 border-primary-400': color === 'primary',
      'w-full': fullWidth,
      'opacity-25': disabled,
    }
  );

  const renderButton = () => {
    if (type === 'button')
      return (
        <button
          className={className}
          type="button"
          onClick={onClick}
          disabled={disabled}
        >
          {children}
        </button>
      );

    if (type === 'submit')
      return (
        <button
          className={className}
          type="submit"
          onClick={onClick}
          disabled={disabled}
        >
          {children}
        </button>
      );

    return null;
  };

  switch (component) {
    case 'button':
      return renderButton();
    case 'a':
      return (
        <Link className={className} to={href}>
          {children}
        </Link>
      );
    default:
      return null;
  }
};

export default Button;
