import type { ReactElement } from 'react';
import classNames from 'classnames';

type LayoutProps = {
  header?: ReactElement;
  headerClassName?: string;
  main: ReactElement;
  route?: string;
  containerClassName?: string
};

const Layout = ({ 
  header, 
  headerClassName, 
  main, 
  route, 
  containerClassName = '' 
}: LayoutProps
) => {
  const defaultContainerClassName = `container pt-4 pb-20 space-y-4 ${containerClassName}`;

  if (!header)
    return (
      <div className="flex-grow min-h-screen dark:bg-black">
        <main className={defaultContainerClassName}>{main}</main>
      </div>
    );

  return (
    <div className="flex min-h-screen flex-col bg-black">
      <header
        className={classNames(
          'container flex flex-col items-center space-y-4 py-4 bg-black',
          headerClassName
        )}
      >
        {header}
      </header>
      <div 
        className={classNames(
          'flex-grow bg-gray-100 dark:bg-dark-background',
          {
            'dark:bg-dark-dark': route === 'CheckoutStepOne',
          }
        )}
      >
        <main className={defaultContainerClassName}>{main}</main>
      </div>
    </div>
  );
};

export default Layout;
