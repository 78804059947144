import { Tab } from '@headlessui/react';
import HomeTab from '../HomeTab';
import HomeTabsGiftCardsPanel from '../HomeTabsGiftCardsPanel';
import HomeTabsPurchaseHistoryPanel from '../HomeTabsPurchaseHistoryPanel';

const HomeTabs = () => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const selectedTab = params.get('selectedTab');

  return (
    <Tab.Group defaultIndex={selectedTab === 'purchaseHistory' ? 1 : 0}>
      <Tab.List className="grid grid-cols-2">
        <HomeTab>Gift cards</HomeTab>
        <HomeTab>Purchase history</HomeTab>
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel>
          <HomeTabsGiftCardsPanel />
        </Tab.Panel>
        <Tab.Panel>
          <HomeTabsPurchaseHistoryPanel />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};

export default HomeTabs;
