import { Disclosure } from '@headlessui/react';
import classNames from 'classnames';
import { IoAddOutline, IoRemoveOutline } from 'react-icons/io5';
import type { FAQ } from '../../types';

type FAQsProps = {
  faqs: FAQ[];
};

const FAQs = ({ faqs }: FAQsProps) => (
  <ul className="divide-y">
    {faqs.map((faq) => (
      <Disclosure key={faq.question} className="space-y-4 py-4" as="li">
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full items-center justify-between">
              <p className="text-left font-bold text-black dark:text-white text-default">
                {faq.question}
              </p>
              <div
                className={classNames(
                  'flex h-8 w-8 items-center justify-center rounded-full',
                  {
                    'bg-gray-200 text-gray-800 dark:bg-dark-foreground dark:text-white':
                      !open,
                    'bg-primary-400 text-black': open,
                  }
                )}
              >
                {!open ? (
                  <IoAddOutline size={20} />
                ) : (
                  <IoRemoveOutline size={20} />
                )}
              </div>
            </Disclosure.Button>
            <Disclosure.Panel>
              <p className="text-black dark:text-white text-default">
                {faq.answer}
              </p>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    ))}
  </ul>
);

export default FAQs;
