import { useDarkMode } from '../../hooks';
import Layout from '../Layout';
import darkCardIcon from '../../images/darkCardIcon.svg';
import cardIcon from '../../images/cardIcon.svg';
import CheckoutNavigationButtons from '../CheckoutNavigationButtons';

const CheckoutStepFive = () => {
  const darkMode = useDarkMode();

  return (
    <>
      <Layout
        containerClassName="pt-40"
        headerClassName="px-0"
        main={
          <div className="flex flex-col items-center space-y-4">
            <img
              className="w-16"
              src={darkMode ? darkCardIcon : cardIcon}
              alt="Card icon"
            />
            <h2 className="text-black font-secondary lowercase font-medium text-default_lg dark:text-white">Pay With Your linked Card</h2>
            <p className="text-center text-gray-500 dark:text-white text-default_lg">
              Make sure to purchase gift cards with a card that’s already linked to your Airtime app. Unlinked cards won’t earn gift card rewards.
            </p>
          </div>
        }
      />
      <CheckoutNavigationButtons />
    </>
  );
};

export default CheckoutStepFive;
