import { useState, useEffect, ChangeEvent, useRef } from 'react';
import classNames from 'classnames';
import type { GiftCard } from '../../types';
import { useCheckout, useDarkMode } from '../../hooks';
import { formatPrice } from '../../utils';
import darkEditIcon from '../../images/darkEditIcon.svg';
import editIcon from '../../images/editIcon.svg';

type DenominationProps = {
  denomination: number;
  onClick: () => void;
  isSelected: boolean;
};

const Denomination = ({
  denomination,
  onClick,
  isSelected,
}: DenominationProps) => (
  <li>
    <button
      className={classNames(
        'w-[4.375rem] rounded-full border-2 bg-gray-100 p-1.5 font-medium dark:text-white dark:bg-dark-foreground dark:text-dark-light font-secondary_num',
        {
          'border-transparent': !isSelected,
          'border-primary-400': isSelected,
        }
      )}
      type="button"
      onClick={onClick}
    >
      {formatPrice(denomination)}
    </button>
  </li>
);

type AmountInputProps = {
  giftCard: GiftCard;
};

const AmountInput = ({ giftCard }: AmountInputProps) => {
  const [selectedDenominationIndex, setSelectedDenominationIndex] = useState(0);
  const {
    setFieldValue,
    setMinValue,
    setMaxValue,
    amountInputValue,
    setAmountInputValue,
    handleChangeAmountInput,
    values,
  } = useCheckout();
  const darkMode = useDarkMode();
  const customDenominations = [1000, 2500, 5000, 10000];

  useEffect(() => {
    if (!giftCard.denominations) {
      const firstAvailableCustomDenomination = customDenominations.find(
        (customDenomination) =>
          customDenomination >= giftCard.min_value &&
          customDenomination <= giftCard.max_value
      );

      setAmountInputValue(
        (
          (firstAvailableCustomDenomination || giftCard.min_value) / 100
        ).toFixed(2)
      );
      setMinValue(giftCard.min_value);
      setMaxValue(giftCard.max_value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (giftCard.denominations)
      setFieldValue(
        'amount',
        giftCard.denominations[selectedDenominationIndex]
      );
  }, [giftCard.denominations, setFieldValue, selectedDenominationIndex]);

  useEffect(() => {
    if (!giftCard.denominations)
      if (amountInputValue)
        setFieldValue('amount', Math.round(parseFloat(amountInputValue) * 100));
      else setFieldValue('amount', 0);
  }, [giftCard.denominations, setFieldValue, amountInputValue]);

  const amountInputValueLength = amountInputValue.split('.').join('').length;
  const inputRef = useRef<HTMLInputElement>(null);

  if (giftCard.denominations)
    return (
      <ul className="flex flex-wrap justify-center gap-[0.625rem]">
        {giftCard.denominations.map((denomination, index) => (
          <Denomination
            key={denomination}
            denomination={denomination}
            onClick={() => {
              setSelectedDenominationIndex(index);
            }}
            isSelected={index === selectedDenominationIndex}
          />
        ))}
      </ul>
    );

  return (
    <div className="space-y-4">
      <button
        className="relative flex w-full items-center justify-center rounded-lg border border-primary-400 p-2.5 font-secondary_num"
        type="button"
        onClick={() => {
          inputRef.current?.focus();
        }}
      >
        <div className="text-4xl text-gray-800 dark:text-white">£</div>
        <input
          className="amount-input border-0 bg-transparent p-0 text-4xl text-gray-800 focus:ring-0 dark:text-white"
          ref={inputRef}
          onClick={(event) => {
            event.stopPropagation();
          }}
          style={{
            width:
              amountInputValueLength > 0
                ? `${amountInputValue.split('.').join('').length + 1}ch`
                : 1,
          }}
          onFocus={() => {
            setAmountInputValue('');
          }}
          type="number"
          maxLength={4}
          value={amountInputValue}
          onChange={handleChangeAmountInput}
          readOnly={giftCard.min_value === giftCard.max_value}
        />
        <img
          className="absolute top-[1.375rem] right-[1.375rem]"
          src={darkMode ? darkEditIcon : editIcon}
          alt="Edit"
        />
      </button>
      <ul className="flex flex-wrap justify-center gap-[0.625rem]">
        {customDenominations.map((customDenomination) => {
          const event = {
            target: {
              value: (customDenomination / 100).toFixed(2),
            },
          } as ChangeEvent<HTMLInputElement>;

          if (
            customDenomination < giftCard.min_value ||
            customDenomination > giftCard.max_value
          ) {
            return null;
          }

          return (
            <Denomination
              key={customDenomination}
              denomination={customDenomination}
              onClick={() => {
                handleChangeAmountInput(event);
              }}
              isSelected={values.amount === customDenomination}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default AmountInput;
