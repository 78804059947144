import { Link } from 'react-router-dom';
import {
  Layout,
  // CategoryFilter,
  HomeTabs,
} from '../../components';

const Home = () => (
  <Layout
    header={
      <p className="text-center text-default text-white">
        Earn rewards when you buy gift cards for you or someone else!{' '}
        <Link className="underline font-medium" to="/how-it-works">
          Find out how it works here.
        </Link>
      </p>
    }
    main={
      <>
        {/* <CategoryFilter /> */}
        <HomeTabs />
      </>
    }
  />
);

export default Home;
