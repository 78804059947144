import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import {
  useFirebaseApp,
  AnalyticsProvider,
  FirestoreProvider,
  FunctionsProvider,
} from 'reactfire';
import { PrismicProvider } from '@prismicio/react';
import { Routes, Route } from 'react-router-dom';
import { Analytics } from './components';
import { client } from './lib/prismic';
import { CheckoutProvider } from './contexts';
import { Home, HowItWorks, OrderSuccess, Checkout, Purchase } from './pages';
import errorIcon from './images/errorIcon.svg';

const Unauthorized = () => (
  <div className="flex h-screen flex-col items-center justify-center gap-2 text-gray-800 dark:text-white">
    <img className="h-14 w-14" src={errorIcon} alt="Success" />
    <div className="text-center">
      <h1 className="text-lg font-medium">Unauthorized</h1>
      <p>Gift cards must be accessed via the Airtime Rewards mobile app</p>
    </div>
  </div>
);

const App = () => {
  const firebaseApp = useFirebaseApp();
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const zorroUserId = params.get('zorro_user_id');
  const canViewApp = Boolean(zorroUserId);

  return (
    <AnalyticsProvider sdk={getAnalytics(firebaseApp)}>
      <FirestoreProvider sdk={getFirestore(firebaseApp)}>
        <FunctionsProvider sdk={getFunctions(firebaseApp, 'europe-west2')}>
          <Analytics />
          <PrismicProvider client={client}>
            <Routes>
              <Route path="/" element={canViewApp ? <Home /> : <Unauthorized />} />
              <Route path="/purchase/:id" element={canViewApp ? <Purchase /> : <Unauthorized />} />
              <Route path="/how-it-works" element={<HowItWorks />}/>
              <Route path="/order-success" element={<OrderSuccess />} />
              <Route path="/:sku" element={
                canViewApp ? (
                  <CheckoutProvider>
                    <Checkout />
                  </CheckoutProvider>
                ) : (
                  <Unauthorized />
                )} />
            </Routes>
          </PrismicProvider>
        </FunctionsProvider>
      </FirestoreProvider>
    </AnalyticsProvider>
  );
};

export default App;
