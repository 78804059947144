import { createContext, useState } from 'react';
import type {
  Dispatch,
  SetStateAction,
  ChangeEvent,
  FocusEventHandler,
  ReactNode,
} from 'react';
import * as yup from 'yup';
import type { FormikErrors, FormikTouched } from 'formik';
import { useFormik } from 'formik';
import type { Values } from '../types';
import { formatPrice, formatPercentage } from '../utils';

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/* 
  MIGRATE YUP FROM "0.32.11" TO "1.4.0"
  change "when" method (https://github.com/jquense/yup/issues/1906) (critical)
*/
const validationSchema = yup.object().shape({
  amount: yup.number().required(),
  recipient: yup.string().oneOf(['myself', 'someone-else']),
  customerFirstName: yup.string().required('First name is required'),
  recipientFirstName: yup.string().when('recipient', {
    is: 'someone-else',
    then: schema => schema.required('First name is required')
  }),
  customerLastName: yup.string().required('Last name is required'),
  recipientLastName: yup.string().when('recipient', {
    is: 'someone-else',
    then: schema => schema.required('Last name is required')
  }),
  deliveryMethod: yup.string().oneOf(['email', 'sms']),
  customerEmailAddress: yup
    .string()
    .matches(emailRegex, 'Invalid email address')
    .when('deliveryMethod', {
      is: 'email',
      then: schema => schema.required('Email address is required')      
    }),
  recipientEmailAddress: yup
    .string()
    .matches(emailRegex, 'Invalid email address')
    .when('deliveryMethod', {
      is: 'email',
      then: schema => schema.when('recipient', {
        is: 'someone-else',
        then: sch => sch.required('Email address is required')   
      })
    }),
  mobileNumber: yup.string().when('deliveryMethod', {
    is: 'sms',
    then: schema => schema.required('Mobile number is required')   
  }),
  message: yup.string().trim().min(2, 'Message must be at least 2 characters'),
  schedule: yup.string().oneOf(['instantly', 'later-date']),
  sendAt: yup.string().when('schedule', {
    is: 'later-date',
    then: schema => schema.required('Date is required')   
  }),
});

const defaultValues: Values = {
  amount: 0,
  recipient: 'myself',
  customerFirstName: '',
  recipientFirstName: '',
  customerLastName: '',
  recipientLastName: '',
  deliveryMethod: 'email',
  customerEmailAddress: '',
  recipientEmailAddress: '',
  mobileNumber: '',
  message: '',
  schedule: 'instantly',
  sendAt: new Date().toISOString(),
};

const CheckoutContext = createContext<{
  currentStepIndex: number;
  setMinValue: Dispatch<SetStateAction<number>>;
  setMaxValue: Dispatch<SetStateAction<number>>;
  amountInputValue: string;
  setAmountInputValue: Dispatch<SetStateAction<string>>;
  values: Values;
  errors: FormikErrors<Values>;
  touched: FormikTouched<Values>;
  recipientIsMyself: boolean;
  handleChange: (event: ChangeEvent) => void;
  handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  setFieldValue: (field: string, value: string | Date | number) => void;
  formattedAmount: string;
  disableDecrement: boolean;
  disableIncrement: boolean;
  amountInvalid: boolean;
  getAmountInvalidMessage: () => string;
  formattedRewardsPercentage: string;
  formattedRewardsAmount: string;
  decrementCurrentStepIndex: () => void;
  incrementCurrentStepIndex: () => void;
  decrementAmountInput: () => void;
  incrementAmountInput: () => void;
  handleChangeAmountInput: (event: ChangeEvent<HTMLInputElement>) => void;
  customer: any;
  setCustomer: (customer: any) => void;
}>({
  currentStepIndex: 0,
  setMinValue: () => {},
  setMaxValue: () => {},
  amountInputValue: '',
  setAmountInputValue: () => {},
  values: defaultValues,
  errors: {},
  touched: {},
  recipientIsMyself: true,
  handleChange: () => {},
  handleBlur: () => {},
  setFieldValue: () => {},
  formattedAmount: '',
  disableDecrement: false,
  disableIncrement: false,
  amountInvalid: false,
  getAmountInvalidMessage: () => '',
  formattedRewardsPercentage: '',
  formattedRewardsAmount: '',
  decrementCurrentStepIndex: () => {},
  incrementCurrentStepIndex: () => {},
  decrementAmountInput: () => {},
  incrementAmountInput: () => {},
  handleChangeAmountInput: () => {},
  customer: {},
  setCustomer: () => {},
});

type CheckoutProviderProps = {
  children: ReactNode;
};

export const CheckoutProvider = ({ children }: CheckoutProviderProps) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [amountInputValue, setAmountInputValue] = useState('0.00');
  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema,
    onSubmit: () => {},
  });
  const recipientIsMyself = formik.values.recipient === 'myself';
  const formattedAmount = formatPrice(formik.values.amount);
  const disableDecrement = formik.values.amount - minValue < 100;
  const disableIncrement = formik.values.amount + 100 > maxValue;
  const amountInvalid =
    formik.values.amount < minValue || formik.values.amount > maxValue;
  const getAmountInvalidMessage = () => {
    if (formik.values.amount < minValue)
      return `Amount must be at least ${formatPrice(minValue)}`;

    if (formik.values.amount > maxValue)
      return `Amount must be less than ${formatPrice(maxValue)}`;

    return '';
  };
  const rewardsPercentage = parseFloat(
    process.env.REACT_APP_REWARDS_PERCENTAGE!
  );
  const formattedRewardsPercentage = formatPercentage(rewardsPercentage);
  const rewardsAmount = formik.values.amount * (rewardsPercentage / 100);
  const formattedRewardsAmount = formatPrice(rewardsAmount);

  const decrementCurrentStepIndex = () =>
    setCurrentStepIndex((prevState) => prevState - 1);

  const incrementCurrentStepIndex = () =>
    setCurrentStepIndex((prevState) => prevState + 1);

  const decrementAmountInput = () =>
    setAmountInputValue((parseFloat(amountInputValue) - 1).toFixed(2));

  const incrementAmountInput = () =>
    setAmountInputValue((parseFloat(amountInputValue) + 1).toFixed(2));

  const handleChangeAmountInput = (event: ChangeEvent<HTMLInputElement>) =>
    setAmountInputValue(event.target.value);

  const [customer, setCustomer] = useState<any>(null);

  return (
    <CheckoutContext.Provider
      value={{
        currentStepIndex,
        setMinValue,
        setMaxValue,
        amountInputValue,
        setAmountInputValue,
        values: formik.values,
        errors: formik.errors,
        touched: formik.touched,
        recipientIsMyself,
        handleChange: formik.handleChange,
        handleBlur: formik.handleBlur,
        setFieldValue: formik.setFieldValue,
        formattedAmount,
        disableDecrement,
        disableIncrement,
        amountInvalid,
        getAmountInvalidMessage,
        formattedRewardsPercentage,
        formattedRewardsAmount,
        decrementCurrentStepIndex,
        incrementCurrentStepIndex,
        incrementAmountInput,
        decrementAmountInput,
        handleChangeAmountInput,
        customer,
        setCustomer,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutContext;
