import type { GiftCard } from '../../types';
import { useCheckout } from '../../hooks';
import Layout from '../Layout';
import OrderSummaryCard from '../OrderSummaryCard';
import Textarea from '../Textarea';
// import RadioGroup from '../RadioGroup';
import DatePicker from '../DatePicker';
import CheckoutNavigationButtons from '../CheckoutNavigationButtons';

type CheckoutStepFourProps = {
  giftCard: GiftCard;
};

const CheckoutStepFour = ({ giftCard }: CheckoutStepFourProps) => {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    useCheckout();

  const getShouldDisableContinue = () => {
    if (values.schedule === 'later-date' && !values.sendAt) return true;

    if (errors.message || errors.sendAt) return true;

    return false;
  };

  return (
    <>
      <Layout
        main={
          <>
            <OrderSummaryCard giftCard={giftCard} />
            <Textarea
              id="message"
              name="message"
              label="Write a message for the recipient"
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              touched={touched}
            />
            {/* <RadioGroup
              value={values.schedule}
              onChange={(value) => setFieldValue('schedule', value)}
              label="When would you like to send this gift card?"
              options={[
                {
                  value: 'instantly',
                  label: 'Send instantly',
                },
                {
                  value: 'later-date',
                  label: 'Send at a later date',
                },
              ]}
            /> */}
            {values.schedule === 'later-date' && (
              <DatePicker
                id="sendAt"
                label="Select date"
                value={values.sendAt}
                onChange={(date) => setFieldValue('sendAt', date)}
              />
            )}
          </>
        }
      />
      <CheckoutNavigationButtons disableContinue={getShouldDisableContinue()} />
    </>
  );
};

export default CheckoutStepFour;
