import type { ChangeEvent, FocusEventHandler, ReactNode } from 'react';
import type { FormikErrors, FormikTouched } from 'formik';
import classNames from 'classnames';

type InputProps = {
  id: string;
  type?: 'text' | 'email' | 'tel';
  name: string;
  label: string;
  value?: string;
  onChange?: (event: ChangeEvent) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  errors?: FormikErrors<any>;
  touched?: FormikTouched<any>;
};

const Input = ({
  id,
  type = 'text',
  name,
  label,
  value = '',
  onChange = () => {},
  onBlur = () => {},
  errors = {},
  touched = {},
}: InputProps) => {
  const showErrors = errors[name] && touched[name];

  return (
    <div className="space-y-2">
      <label className="label" htmlFor={id}>
        {label}
      </label>
      <input
        className={classNames('input', {
          'input-error-border': showErrors,
        })}
        id={id}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {showErrors &&  <p className="input-error">{errors[name] as ReactNode}</p>}
    </div>
  );
};

export default Input;
