import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import { IoChevronForwardOutline } from 'react-icons/io5';
import type { GiftCard } from '../../types';
import { formatPercentage } from '../../utils';

type GiftCardListProps = {
  data: any;
  giftCards: GiftCard[];
  next: () => void;
};

const GiftCardList = ({ data, giftCards, next }: GiftCardListProps) => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const zorroUserId = params.get('zorro_user_id');

  return (
    <InfiniteScroll
      className="space-y-[0.625rem]"
      style={{
        overflow: 'visible',
      }}
      dataLength={giftCards.length}
      hasMore={data.meta.current_page < data.meta.last_page}
      next={next}
      loader={
        <p className="text-center text-sm text-gray-800 dark:text-white">
          Loading more...
        </p>
      }
    >
      {giftCards.map((giftCard, i) => {
        const availability = [];

        if (giftCard.meta.online) availability.push('Online');
        if (giftCard.meta.in_store) availability.push('In-store');

        return (
          <li className="h-[4.375rem] list-none" key={giftCard.sku + String(i)}>
            <Link
              className="flex h-full items-center rounded-lg bg-white px-4 dark:bg-dark-foreground"
              to={`/${giftCard.sku}?zorro_user_id=${zorroUserId}`}
            >
              <p className="mr-4 border-r-2 border-r-gray-200 pr-4 text-default_xl font-medium text-primary-400 dark:border-r-dark-background">
                {formatPercentage(
                  parseFloat(process.env.REACT_APP_REWARDS_PERCENTAGE!)
                )}
              </p>
              <div className="flex flex-grow items-center justify-between">
                <div className="flex items-center space-x-4">
                  <img
                    className="h-10 w-10 rounded-full border border-gray-200 object-cover"
                    src={giftCard.meta.image}
                    alt={giftCard.brand}
                  />
                  <div>
                    <h2 className="font-medium text-black dark:text-white font-secondary lowercase text-default">
                      {giftCard.brand}
                    </h2>
                    <p className="font-medium text-gray-500 dark:text-white text-default_sm">
                      {availability.join(' & ')}
                    </p>
                  </div>
                </div>
                <IoChevronForwardOutline
                  className="text-gray-800 dark:text-white"
                  size={20}
                />
              </div>
            </Link>
          </li>
        );
      })}
    </InfiniteScroll>
  );
};

export default GiftCardList;
