import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import {
  collection,
  CollectionReference,
  query,
  where,
  orderBy,
} from 'firebase/firestore';
import type { Purchase } from '../../types';

import PurchaseHistoryList from '../PurchaseHistoryList';

const HomeTabsPurchaseHistoryPanel = () => {
  const firestore = useFirestore();
  const ref = collection(firestore, 'orders') as CollectionReference<Purchase>;
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const zorroUserId = params.get('zorro_user_id');
  const ordersQuery = query<Purchase, any>( // TODO: Purchase type
    ref,
    where('schemaVersion', '==', 2),
    where('zorroUserId', '==', zorroUserId),
    orderBy('created', 'desc')
  );
  const { data: purchaseHistory } = useFirestoreCollectionData<Purchase>(
    ordersQuery,
    {
      idField: 'id',
    }
  );

  if (!purchaseHistory) {
    return null;
  }

  return <PurchaseHistoryList purchaseHistory={purchaseHistory} />;
};

export default HomeTabsPurchaseHistoryPanel;
