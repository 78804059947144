import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData, useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import dayjs from 'dayjs';
import { formatPrice } from '../../utils';
import { Layout, BackButton } from '../../components';

type ViewGiftCardButtonProps = {
  order: any;
};

const ViewGiftCardButton = ({ order }: ViewGiftCardButtonProps) => {
  const functions = useFunctions();
  const getOrder: any = httpsCallable(functions, 'getOrder');
  const [simfoniOrder, setSimfoniOrder] = useState<any>(null);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const recursiveGetOrder = (reference: string) => {
      getOrder({
        reference,
      })
        .then(({ data }: any) => {
          if (!data.data.items[0].details.url) {
            timeout = setTimeout(() => {
              recursiveGetOrder(reference);
            }, 5000);

            return null;
          }

          return setSimfoniOrder(data.data);
        })
        .catch(() => {
          timeout = setTimeout(() => {
            recursiveGetOrder(reference);
          }, 5000);
        });
    };

    if (!simfoniOrder) {
      recursiveGetOrder(order.reference);
    }

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simfoniOrder]);

  return simfoniOrder ? (
    <a
      className="block rounded-full border border-primary-400 bg-primary-400 p-[0.6875rem] px-[31px] text-center text-default font-medium text-black font-secondary lowercase"
      href={simfoniOrder.items[0].details.url}
    >
      View gift card
    </a>
  ) : (
    <p className="text-base text-white">Retrieving gift card...</p>
  );
};

const Purchase = () => {
  const { id } = useParams<{
    id: string;
  }>();
  
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const zorroUserId = params.get('zorro_user_id');

  const firestore = useFirestore();
  const ref = doc(firestore, 'orders', id as string);
  const { data: order } = useFirestoreDocData(ref);

  if (!order) return null;

  const formattedAmount = formatPrice(order.amount);
  const rewardsPercentage = parseFloat(
    process.env.REACT_APP_REWARDS_PERCENTAGE!
  );
  const rewardsAmount = order.amount * (rewardsPercentage / 100);
  const formattedRewardsAmount = formatPrice(rewardsAmount);
  

  return (
    <Layout
      header={
        <div className="grid w-full grid-cols-[2.5rem,1fr,2.5rem] items-center">
          <BackButton href={`/?zorro_user_id=${zorroUserId}&selectedTab=purchaseHistory`} />
          <div className="flex items-center justify-center">
            <div className="text-center">
              <p className="font-medium leading-tight text-white font-secondary lowercase text-default">
                {order.brand}
              </p>
              <p className="text-base font-medium leading-tight text-white text-default_sm mt-1">
                {`${formattedAmount} gift card`}
              </p>
            </div>
          </div>
        </div>
      }
      main={
        <div className="bg-white dark:bg-dark-border px-4 rounded-lg">
          <div className="flex flex-col items-center space-y-4 py-8 text-center">
            <img
              className="h-14 w-14 rounded-full border border-gray-200 object-cover"
              src={order.thumbnail}
              alt={order.brand}
            />
            <div>
              <h1 className="text-black dark:text-white font-secondary text-default lowercase">
                {`${formattedAmount} ${order.brand} gift card`}
              </h1>
              <p className="text-black dark:text-white text-default mt-1">{`Order reference: ${ref.id}`}</p>
            </div>
          </div>
          <div className="space-y-4 py-8 text-center border-t border-gray-200 dark:border-black">
            <p className="text-default fomt-medium text-primary-400">{`Your ${formattedRewardsAmount} Airtime Rewards will be added to your balance.`}</p>
            <p className="text-default text-black dark:text-white">
              It can take 3-5 days for this to appear
            </p>
            {order && <ViewGiftCardButton order={order} />}
            <p className="text-default_sm text-black dark:text-white">
              {dayjs.unix(order.created).format('DD/MM/YYYY h:mma')}
            </p>
          </div>
        </div>
      }
    />
  );
};

export default Purchase;
