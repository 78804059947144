import { useState, useEffect } from 'react';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import type { GiftCard } from '../../types';
import GiftCardList from '../GiftCardList';

const HomeTabsGiftCardsPanel = () => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState<any>();
  const [giftCards, setGiftCards] = useState<GiftCard[]>([]);
  const functions = useFunctions();
  const getGiftCards = httpsCallable(functions, 'getGiftCards');

  useEffect(() => {
    getGiftCards({
      page,
    }).then((value: any) => setData(value.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (data) setGiftCards((prevState) => [...prevState, ...data.data]);
  }, [data]);

  if (!data) {
    return null;
  }

  return (
    <GiftCardList
      data={data}
      giftCards={giftCards}
      next={() => setPage((prevState) => prevState + 1)}
    />
  );
};

export default HomeTabsGiftCardsPanel;
